/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

/*
 * App CSS
 * ----------------------------------------------------------------------------
 * Imports a file that can contain Sass/CSS that should be used throughout
 * the entire app.
 */

 @import "./app/app.scss";


 .h1, .h2, .h3, .h4, .h5, .h6, button, h1, h2, h3, h4, h5, h6, p, div{
	font-family: 'Poppins', sans-serif;	
}

button:focus{
    outline: 0 !important;
}
img.pocn-logo-header {
    width: 225px;
}
.pocn-header-menu{
	width: 100%;
	display: block;
	padding: 0;
}
.header-menu-pocn{
	float: right;
	padding-top: 20px;
	text-align: right;
}
.register-button button, .courses-content-section button, .userprofile-details-section .user-connect button{
	background: #5ab2e1;
    color: #fff;
    border: 0;
    cursor: pointer;
    padding: 5px 15px;
    border-radius: 5px;
    margin-right: 15px;
}
/*.register-button button:hover, button.signin-btn: hover, .courses-content-section button:hover{
	background:#94c83d;
}*/
.footer-menu a{
	font-family: 'Poppins', sans-serif;	
}
.footer-menu li{
	list-style-type: none;
}
.footer-menu li a{
	color: #aaaaaa;
	font-size: 14px;
}
.footer-menu li a:hover{
	text-decoration: none;
	color: #94c83d;
}
.footer-menu {
    padding-top: 12px;
}
.copyright {
    color: #777777;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;	
}
.social-media {
    text-align: right;
}
.social-media li{
	display: inline-block;
	margin-left: 15px;
}
.social-media i {
    color: #777777;
    font-size: 18px;
}
.social-media a:hover > i{
	color: #2ac1f1;
}
.welcome-page ion-row {
    background-color: #fff;
}
ion-grid{
    padding: 0 !important;
}
.welcome-page ion-grid, .welcome-page ion-content {
    height: 100%;
    background-color: #fff;
  }
  .error-msg {
    font-size: 11px;
    color: rgb(255 0 0);
    font-weight: 400;
    line-height: 11px;
    padding-top: 3px;
}
 
.home-banner, .registration-banner{
	//background-image: url(../images-pocn/pocn-home-theme-banner.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    border-width: 0px;
    border-color: rgb(234, 233, 233);
    border-style: solid;
    background-size: cover;
    height: 100vh;
    
}
.registration-banner{
	margin-bottom: 0;
    text-align: center;
    width: 100%;
    display: inline-block;
}
.registration-banner{
    background-image: url('./assets/images-pocn/pocn-home-theme-banner.jpg');
}
.registration-form{
	background-color: rgba(0, 0, 0, 0.5);
    width: 700px;
    border-radius: 10px;
    position: relative;
    top: 3%;
    text-align: left;
    padding: 20px;
    font-family: 'Poppins', sans-serif;
    margin: auto;
}
.registration-form .error-msg{
    margin-bottom: -12px;
}
.acknowledge-text {
    margin-top: 10px;
}
.lookup-button{
    width: 100%;
}
.registration-form .input-label{
    color: #fff !important;
    font-size: 14px;
    font-weight: 500 !important; 
}
.registration-form h2 {
    font-family: 'Poppins', sans-serif;
    color: #fff;
    font-size: 22px;
    display: inline-block;
    text-align: center;
    width: 100%;
    margin: 0;;
}
.contact-form-section ion-textarea, .contact-form-section textarea, ion-textarea {
    resize: horizontal !important;
    resize: vertical !important;
    min-height: 100px;
}
.valid-pass-alert {
    color: rgb(255 255 255);
    font-size: 14px;
    text-align: center;
}
.valid-pass-alert-col {
    padding: 0 !important;
}
.registration-form h2 span {
    color: #2ac1f1;
}
.registration-form form{
	text-align: left;
    margin-bottom: 0;
}
.registration-form form label{
	color: #fff;
	margin-bottom: 0;
	font-size: 14px;
}
.registration-form input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a8a8a8;
  font-size: 14px;
  
}

.registration-form input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #a8a8a8;
  font-size: 14px;
}

.registration-form input::-ms-input-placeholder { /* Microsoft Edge */
  color: #a8a8a8;
  font-size: 14px;
}
.registration-form input {
    color: #2b2b2b !important;
    padding: 7px 0 !important;
}
.registration-form ion-input {
    border-radius: 0.25rem !important;
}
// .registration-form .ion-padding-vertical{
// padding: 8px !important;
// }
.graduation-details ion-col{
    width: 50%;
}
.registration-banner ion-col {
    padding-top: 10px;
    padding-bottom: 10px;
}
.lookup-button, .signin-btn, ion-button.get-started{
    margin: 0 !important;
    --background: rgb(90 178 225);
}
.graduation-details ion-item{
    min-height: 1px !important;
}
.graduation-details ion-list{
    padding: 0 !important;
}
.graduation-select {
    background-color: #fff;
    width: 100%;
    border-radius: 4px;
    padding-right: 5px;
}
.graduation-details .item-native{
    padding: 0 !important;
}
.graduation-details .select-text {
    color: #000;
    font-size: 14px !important;
}
.graduation-select ion-select {
    color: #000;
    font-size: 14px;
    padding-left: 8px !important;
}
// .lookup-button span {
//     background-color: #2ac1f1;
//     border: 0;
//     color: #fff;
//     border-radius: 4px;
//     padding: 7px 15px;
//     margin-top: 20px;
//     cursor: pointer;
//     width: 100%;
// }
.signin-btn11{
    background-color: #2ac1f1;
    border: 0;
    color: #fff;
    border-radius: 4px;
    padding: 7px 15px;
    cursor: pointer;
    margin-top: 10px;
}
.my-custom-class .modal-wrapper {
    background-color: #fff;
    border-radius: 8px;
}
ion-row.lookup-npi-submit {
    margin-bottom: 15px;
}
.lookup-inputfield {
    background-color: #dcdcdc;
}
.lookup-inputfield input, .lookup-modal-col ion-label{
    color: #000 !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    font-size: 16px !important;
}
.profile-icon-right{
    position: relative;
}
.profile-icon{
    position: absolute;
    right: 0;
    color: rgb(66 66 66);
}
ion-label {
    color: rgb(0 0 0) !important;
}
ion-col.addButton{
    text-align: right;
}
.lookup-select ion-select {
    background-color: #dbdbdb;
    color: #000 !important;
    padding-right: 15px;
    --placeholder-opacity: 0.5;
}
ion-button.modal-close-button {
    color: rgb(168 168 168);
    font-size: 20px;
    margin-top: -10px;
    margin-right: -10px;
}
h2.lookup-title {
    color: #2ac1f1;
    font-weight: 600;
    padding-left: 10px;
    margin-bottom: 0;
    font-size: 20px;
}
.lookup-modal-col {
    padding: 0;
}
.privacy-note p{
    color: #fff;
    font-size: 11px;
    line-height: 13px;
}
.privacy-note p a {
    font-weight: 700;
    color: #2ac1f1;
}
button.signin-btn {
    background-color: #2ac1f1;
    border: 0;
    color: #fff;
    border-radius: 4px;
    padding: 7px 15px;
    cursor: pointer;
    margin-top: 10px;
}
.pocn-header-menu li a{
	color: #fff;
    border-radius: 10px;
    padding: 0 15px;
    border-bottom: 0;
    font-family: 'Poppins', sans-serif;	
}
.pocn-header-menu li a.nav-link {
    margin-right: 15px;
	margin-top: 6px;
}
li.register-button a{
	margin-right: 0 !important;
}
.pocn-header-menu li a.nav-link:hover {
    border-bottom: 3px solid #94c83d;
    border-radius: 0;
}
.home-banner{
	text-align: center;
}
.home-banner h2{
	    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 60px;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin-top: 12%;
}
.aboutus-home {
    padding: 50px;
    text-align: center;
}
.aboutus-home h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 35px;
    font-weight: 700;
    color: #444;
    margin-bottom: 20px;
}
.lookup-modal {
    margin-bottom: 15px;
}
.aboutus-home p {
    line-height: 25px;
    color: #686868;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    display: inline-block;
    margin-bottom: 35px;
}
a.registernow-btn {
    background: #5ab2e1;
    color: #fff;
    border-radius: 10px;
    padding: 10px 25px;
    border: 0;
    outline: none !important;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
}
a.registernow-btn:hover{
	text-decoration: none;
	background: #94c83d;
}
/*.header-menu-pocn .navbar-collapse.collapse.show {
    position: absolute;
    left: 0;
    right: 0;
    text-align: left;
    background-color: #9c9c9c;
}*/
.lookup-popup h4 {
    color: #5ab2e1;
    font-weight: 700;
}
.lookup-popup {
    font-family: 'Poppins', sans-serif;
}
.lookup-popup .modal-header {
    border-bottom: 0;
    padding-bottom: 0;
}
.lookup-popup .form-control{
    background-color: #b8b9bc;
    color: #fff;
    font-weight: 700;
    margin-bottom: 5px;
}
.lookup-popup button {
    background-color: #5ab2e1;
    border: 0;
    font-weight: 700;
    margin-top: 10px;
}
.lookup-popup button:hover{
	background-color: #94c83d;
}
h2.selcet-npi {
    color: #5ab2e1;
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 10px 15px;
}
select#npi-results {
    background-color: #fff;
    padding: 0;
    border: 1px solid #e7e7e7;
}
ion-button.npi-results-row {  
    --background: none !important;
    color: #000 !important;
    border-bottom: 1px solid rgb(114 114 114);
    text-transform: capitalize;
    width: 100%;
    text-align: left !important;
    font-size: 13px;
    line-height: 15px !important;
    margin-top: 0;
  }
  .lookup-submit {
    --background: rgb(148 200 61) !important;
    width: 100%;
}
.lookup-popup .modal-footer{
	border-top: 0 !important;
}
.lookup-popup button.btn-close {
    background-color: transparent;
 }
select#npi-results option {
    color: #6b6b6b;
    font-size: 13px;
    text-transform: uppercase;
    border-bottom: 1px solid #e7e7e7;
    padding: 7px;
    cursor: pointer;
}
.confirm-npi-selection {
    background-color: #b8b9bc;
    width: 100%;
    padding: 10px 15px;
    margin: 0 15px;
}
.selected-name-display {
    color: #fff;
    font-weight: 600;
}
button#btn-npi-confirm-selection {
    font-weight: 400;
    font-size: 13px;
    margin-top: 10px;
}
select#npi-results option:hover {
    background-color: #94c83d;
    color: #fff;
}
select#npi-results:focus {
    outline: 0 !important;
    box-shadow: none !important;
}
.selected-city-display {
    color: #fff;
    font-size: 14px;
}
.btn-check:focus+.btn-primary, .btn-primary:focus, select#npi-results option:focus {
    color: #fff;
    background-color: #94c83d !important;
    border-color: transparent !important;
    box-shadow: none !important;
}
.thankyou-section p {
    color: #fff;
    font-size: 15px;
}
.thankyou-section h2 {
    color: #2ac1f1;
}
.green-button {
    background-color: #94c83d;
    color: #fff;
    border: 0;
    cursor: pointer;
    padding: 5px 15px;
    border-radius: 5px;
}
.green-button:hover {
    background-color: #2ac1f1;
}
.lookup-popup .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  opacity: 1; /* Firefox */
}

.lookup-popup .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #fff;
  font-weight: 700;
  font-size: 14px;
}

.lookup-popup .form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: #fff;
  font-weight: 700;
  font-size: 14px;
}
.user-details-section h3 {
    font-size: 22px;
    font-weight: 700;
    color: #444;
}
.user-details-section h2, .user-info-section h2 {
    font-size: 18px;
    font-weight: 700;
    color: #444;
    margin-bottom: 10px;
    margin-top: 10px;
}
.user-avatar button {
    display: block;
    width: 200px;
    margin-top: 15px;
    background-color: #607d8b;
    border: 0;
    color: #fff;
    border-radius: 5px;
    padding: 5px 15px;
    cursor: pointer;
}
.user-avatar .file-upload {
    width: 165px;
    display: block;
    margin-top: 15px;
    font-size: 12px;
    color: #000;
}
label.custom-control-label {
    font-size: 14px;
}
a.register-now {
    color: #94c83d;
}
.login-footer-links p {
    color: #fff;
    font-size: 15px;
    margin-bottom: 5px;
}
a.forgot-pass {
    color: #2ac1f1;
    font-size: 14px;
}
button.login_button {
    margin-right: 15px;
}
nav.pocn-header-menu.header-menu-dark li a.nav-link, nav.pocn-header-menu.header-menu-dark li a.nav-link:hover {
    color: #404040 !important;
}
form#proForm [type=submit] {
    background-color: #2ac1f1;
    border: 0;
    color: #fff;
    border-radius: 5px;
    padding: 10px 20px;
    font-weight: 700;
    margin-top: 15px;
}
form#proForm [type=submit]:hover {
    background-color: #94c83d;
}
.privacy-note p {
    margin-bottom: 3px;
}
span.error-register {
    color: red;
    display: block;
    font-size: 12px;
    border-radius: 3px;
    border: 1px solid #fff;
    margin-bottom: 10px;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.4);
}
.sign-in{
    text-align: center;
}
button.user_profile{
    margin-right: 15px;
}
.pocn-header-menu li a.nav-link {
    color: #fff !important;
}
.errorMsg {
    color: red;
    font-size: 11px;
    line-height: 12px;
}
.privacy-note {
    margin-top: 5px;
}
.npi-readonly {
    background-color: #a3b3c2 !important;
    cursor: no-drop;
}
.user-details-section p {
    margin-bottom: 0;
    text-transform: capitalize;
}
span.required-star {
    color: red;
    margin-left: 5px;
}
.loader{
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('//upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Phi_fenomeni.gif/50px-Phi_fenomeni.gif') 50% 50% no-repeat rgb(249,249,249);
  }
.profilepage-banner{
    background-color: #2ac1f1;
    height: 200px;
    position: relative;
}
.user-profile-section {
    padding: 50px;
    margin-top: -155px;
    background-color: #f7f7f7;
}
.user-details-section h4 {
    font-size: 15px;
    font-weight: 700;
    color: #444;
    margin-top: 25px;
}
.user-details-section{
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 35px 35px 35px;
    /*box-shadow: 0px 4px 7px 1px rgba(0,0,0,0.54);
    -webkit-box-shadow: 0px 4px 7px 1px rgba(0,0,0,0.54);
    -moz-box-shadow: 0px 4px 7px 1px rgba(0,0,0,0.54);  */
    
}
.user-avatar img {
    border-radius: 50%;
    width: 190px;
    position: relative;
}
 .user-avatar button:hover{
    background-color: #2ac1f1;
    color: #fff;
 }

.profile-image-edit{
    background-color:rgba(0, 0, 0, 0.5);
    width: 190px;
    height: 190px;
    border-radius: 50%;
    text-align: center;
    display: none;
    padding-top: 18%;
}
.profile-image-edit i{
    color: #fff;
}
.user-avatar img:hover ~ .profile-image-edit{
    display: block !important;
    margin-top: -190px;
    z-index: 9999;
    position: relative;
}
.innerpage-banner{
    height: 300px;
}
.coe-landing {
    padding: 50px 0;
}
.coe-landing img {
    margin-bottom: 25px;
    border: 0;
}
.innerpage-banner {
    overflow: hidden;
}
.innerpage-banner img {
    padding: 0;
}
.mobile-top-tab li img, .mobile-bottom-tab li img {
    width: 35px;
}
/*.mobile-top-tab, .mobile-bottom-tab{
    display: none;
}*/
.mobile-bottom-tab ul{
    display: flex;
    border: 0;
}
li.profile-tab-li, .mobile-bottom-tab li{
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
}
.mobile-bottom-tab {
    position: fixed;
    bottom: 90px;
    background-color: #fff;
    padding-top: 10px;
    z-index: 9;
    padding-bottom: 10px;
}
.pocn-breadcrumb .breadcrumb{
    margin: 0;
    background-color: #f9f9f9;
}
.pocn-breadcrumb .breadcrumb a{
    color: #3f3f3f;
}
.pocn-breadcrumb .breadcrumb .active{
    color: #2ac0f2;
}
.pocn-breadcrumb .breadcrum{
    font-size: 13px;
}
.pocn-breadcrumb span.fusion-breadcrumb-sep {
    color: #2ac0f2;
    margin: 0 7px;
    font-size: 16px;
    letter-spacing: -2px;
}
.filter-courses button{
    background: #94c83d;
    color: #fff;
    border: 0;
    cursor: pointer;
    padding: 5px 15px;
    border-radius: 5px;
    margin-right: 15px;
}
.filter-courses{
    text-align: right;
    margin-top: -250px;
    margin-bottom: 25px;
}
.courses-main-block{
    background-color: #fff;
    border: 1px solid #c2c2c2;
    width: 100%;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    display: inline-block;
}
.courses-main-block img{
    width: 100%;
    border-radius: 10px;
}
.dept-date-section {
    min-height: 30px;
    line-height: 14px;
}
.dept-date-section span {
    float: left;
    color: #707070;
    font-size: 10px;
}
.date {
    font-style: italic;
}
.courses-main-block h2 a{
    color: #707070;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    line-height: 10px;
}
.courses-main-block h2{
    line-height: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}
span.dept-date-separator{
    margin:0 5px;
}
span.dept-name {
    text-transform: capitalize;
}
.courses-main-block p {
    font-size: 12px;
    color: #707070;
    float: left;
    margin-bottom: 10px;
    line-height: 18px;
    margin-top: 5px;
}
.block-right-cnt {
    background-color: #ecfafe;
    width: 100%;
    box-sizing: border-box;
    border-radius: 0 0 0px 0px;
    box-sizing: border-box;
    display: flex;
}
.credit-block {
    flex-basis: 100%;
    color: #707070;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
    padding: 5px 0;
    line-height: 22px;
    box-sizing: border-box;
    border-right: 1px solid #b3bbbd;
}
.courses-top-content{
    padding: 15px;
}
.block-right-cnt .credit-block:last-child {
    border-right: 0 !important;
}
.start-expires-section {
    padding: 15px 15px;
    display: flex;
}
.start-expires-section span {
    flex-basis: 100%;
}
.start-expires h3 {
    color: #707070;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 5px;
}
.start-expires p {
    color: #707070;
    font-size: 11px;
    margin-top: 5px;
    line-height: 14px;
    margin-bottom: 0;
}
.pocn-footer {
    margin-top: 25px;
}
.innerpage-banner-blue{
    background-color: #5ab2e1;
}
.course-description-sections img{
    width: 100%;
}
.course-description-sections{
    padding: 15px 0;
}
.course-description{
    font-size: 32px;
    color: #fff;
    margin-bottom: 50px;
    font-weight: 700;
}
span.dept-name-date {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
}
.courses-content-section-highlight{
    background-color: #f4f4f4;
    padding: 25px 0;
}
.courses-content-section{
    padding: 25px 0;
}
.courses-content-section h2, .courses-content-section-highlight h2{
    color: #707070;
    font-size: 22px;
    margin-bottom: 0px;
    margin-top: 0;
    text-transform: uppercase;
    font-weight: 700;
}
.courses-content-section h3, .courses-content-section-highlight h3{
    color: #707070;
    font-size: 18px;
    margin: 10px 0;
    font-weight: 700;
}
.courses-content-section p, .courses-content-section-highlight p{
    color: #707070;
    font-size: 15px;
}
.faculty-img img{
    
}
.mobile-top-tab li a{
    color: #5ab2e1;
    text-transform: capitalize;
}
.mobile-top-tab li a:hover{
    text-decoration: none;
    color: #94c83d;
}
/*.mobile-top-tab li.active a{
    color: #94c83d;
}*/
.mobile-bottom-tab{
    display: none;
}
.tab-content {
    margin-top: 25px;
}
.tab-content .dept-date-section {
    padding-top: 10px;
}
span.bookmark-icon img {
    width: 20px;
    border-radius: 0;
}
span.bookmark-icon {
    position: absolute;
    right: 15px;
    top: -6px;
}
/*#bookmarks .courses-main-block{
    border: 0;
}*/
#bookmarks .courses-top-content, #engagements .courses-top-content {
    float: left;
}
.bookmark-image {
    width: 40%;
    float: left;
}
.bookmark-text-content {
    width: 60%;
    float: left;
    padding-left: 20px;
}
#bookmarks .courses-main-block {
    position: relative;
}
.bookmarks-block{
    margin-bottom: 15px;
}
span.engagements-date {
    color: #2ac1f1;
}

.profile-subtabs .nav-tabs > li.active > a, .profile-subtabs .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover { border-width: 0; }
.profile-subtabs .nav-tabs > li > a { border: none; color: #ffffff;background:#5ab2e1; display: block; padding: 10px 0;}
.profile-subtabs .nav-tabs > li.active > a, .profile-subtabs .nav-tabs > li > a:hover { border: none;  color: #fff !important; background: #94c83d; }
.profile-subtabs .nav-tabs > li.active > a::after, .profile-subtabs .nav-tabs > li:hover > a::after { transform: scale(1); }
.profile-subtabs .profile-subtabs .tab-nav > li > a::after { background: #5a4080 none repeat scroll 0% 0%; color: #fff; }
.profile-subtabs .tab-pane { padding: 15px 0; }
.profile-subtabs .tab-content{padding:20px; background-color: #f2f6fa; margin-top: 0;}
.profile-subtabs .nav-tabs > li  {width:20%; text-align:center;  border-right: 1px solid #fff;}
.profile-subtabs .nav-tabs > li:last-child{border-right: 0;}
.profile-subtabs .card {background: #FFF none repeat scroll 0% 0%; margin-bottom: 30px; }

.connections-accordion #accordion .glyphicon { margin-right:10px; }
.connections-accordion .panel-collapse>.list-group .list-group-item {border-width: 1px 0; padding: 10px;}
.connections-accordion .panel-collapse>.list-group {margin-bottom: 0;}
.connections-accordion .panel-collapse .list-group-item {border-radius:0;}

.connections-accordion .panel-collapse .list-group .list-group {margin: 0;margin-top: 10px;}
.connections-accordion .panel-collapse .list-group-item li.list-group-item {margin: 0 -15px;border-top: 1px solid #ddd !important;border-bottom: 0;padding-left: 30px;}
.connections-accordion .panel-collapse .list-group-item li.list-group-item:last-child {padding-bottom: 0;}

.connections-accordion .panel-collapse div.list-group div.list-group{margin: 0;}
.connections-accordion .panel-collapse div.list-group .list-group a.list-group-item {border-top: 1px solid #ddd !important;border-bottom: 0;padding-left: 30px;}
.connections-accordion .panel-collapse .list-group-item li.list-group-item {border-top: 1px solid #DDD !important;}
.connections-accordion .panel-default>.panel-heading {color: #333; background-color: #c9d4df; border-color: #ddd; padding: 0 10px;}
.connections-accordion .panel-default>.panel-heading a{color: #363636; font-size: 16px;}
.connections-accordion .panel-title{
    margin-bottom: 0;
}
.connections-accordion .panel-title img {
    width: 35px;
    margin-right: 10px;
}
.connections-accordion .panel-heading{
    border-radius: 5px;
}
.connections-accordion .panel{
    margin-bottom: 2px;
}


.profile-subtabs {
    margin: 20px 0;
}
.profile-subtabs .nav-tabs > li span{
    display: block;
}
.view-connections-cnt li{
        float: left;
        flex-grow: unset !important;
        flex-basis: auto;
        margin-bottom: 1px;
        text-align: unset;
}
.view-connections-cnt li span{
        display: inline-block;
}
span.connect-name ul {
    padding: 0;
    display: block;
}
ul.list-group.view-connections-cnt li {
    display: inherit;
}
ul.profile-tab-section{
    display: flex;
    border: 0;
}
span.connect-avatar {
    width: 20%;
}
span.connect-name {
    width: 60%;
}
span.connect-chat {
    width: 20%;
}
.connect-username {
    font-size: 14px;
    font-weight: 600;
}
.connect-city, .connect-specialty{
    font-size: 11px;
}
.connections-accordion .panel-default>.panel-heading a:hover{
    text-decoration: none;
}
.connections-accordion .panel-default>.panel-heading:hover{
    background-color: #b6c1cd;
}
.connections-accordion{
    padding: 0 !important;
}
span.connect-chat img {
    width: 29px;
    float: right;
}
img{
    -webkit-filter: blur(0px);
-moz-filter: blur(0px);
-ms-filter: blur(0px);
//filter:progid:DXImageTransform. Microsoft. Blur(PixelRadius='0');
}
span.user-edit-icon {
    float: right;
    margin-top: -5px;
}
span.user-edit-icon a {
    color: #9e9e9e;
    border-radius: 50%;
    border: 1px solid #9e9e9e;
    width: 25px;
    height: 25px;
    display: inline-block;
    text-align: center;
}
span.user-edit-icon a:hover{
    color: #5ab2e1;
    border: 1px solid #5ab2e1;
}
.user-profile img {
    width: 35px;
}
.user-profile span {
    color: #333;
}
.tagline h2 {
    margin-top: 130px;
    font-size: 22px;
}
.userprofile-details-section {
    margin: 25px 0;
    font-size: 14px;
}
.user-connect button {
    float: right;
    margin: 0 !important;
}
.userprofile-details-section h2 {
    font-size: 20px;
    color: #5ab2e1;
}
button.add {
    border: 2px solid #94c83d;
    color: #94c83d;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
}
button.add:hover {
    border-color: #5ab2e1;
    color: #5ab2e1;
}
.award-img img {
    width: 80%;
}
.award-img {
    width: 25%;
    float: left;
}
.award-data {
    width: 75%;
    float: left;
}
.award-data span {
    display: block;
    font-size: 15px;
}
span.award-name {
    color: #5ab2e1;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}


.publications-img img {
    width: 80%;
}
.publications-img {
    width: 25%;
    float: left;
}
.publications-data {
    width: 75%;
    float: left;
}
.publications-data span {
    display: block;
    font-size: 15px;
}
span.publications-name {
    color: #5ab2e1;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
}
li.profile-tab-li {
    border: 1px solid #ced4da;
    margin-right: -1px;
    padding: 10px 0;
}
.userpage-profile-data .tab-content {
    margin-top: 0;
    background-color: #f9f9f9;
}
.userpage-profile-data .profile-subtabs{
    margin: 0;
    padding: 0;
}
.userpage-profile-data .tab-pane{
    padding: 15px;
}
.awards-block, .publications-block {
    background-color: #fff;
    display: inline-block;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #f2f2f2;
}
.tab-content .active{
    display: block;
}
span.tagline-edit {
    float: right;
}
span.tagline-edit a {
    color: #5ab2e1;
    font-size: 17px;
}
.user-profile-image {
    text-align: center;
    position: relative;
    width: 190px;
    margin: auto;
}
.user-profileimage-edit img {
    width: 25px !important;
    border-radius: 0;
}
.user-profileimage-edit {
    position: absolute;
    bottom: -4px;
    background-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    right: 30px;
    text-align: center;
    padding-top: 5px;
    padding-left: 3px;
}
.user-banner-edit{
    position: absolute;
    bottom: 15px;
    background-color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    right: 30px;
    text-align: center;
    padding-top: 0px;
    padding-left: 3px;
}
.edit-banner {
    text-align: right;   
}
.edit-banner i{
    color: #fff;
}
.user-banner-edit img {
    width: 20px;
    border-radius: 0;
}
.usersname {
    text-align: center;
}
.usersname h2{
    font-size: 20px;
    font-weight: 700;
    margin-top: 15px;
}
.user-qualification {
    color: #707070;
    font-size: 15px;
}
.connection-details {
    background-color: #41b6e6;
    padding: 10px;
    max-width: 40%;
    margin: auto;
    border-radius: 10px;
    display: flex;
    margin: 20px auto;
}
.connection-block{
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
}
.connection-block span {
    color: #fff;
    display: block;
}
span.connection-count {
    font-size: 20px;
    font-weight: 700;
}
span.connection-text {
    font-size: 15px;
}
.engagement-score {
    text-align: center;
    color: #000;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
}
.contact-user span {
    display: block;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 600;
}
.contact-user i img {
    width: 20px;
    border-radius: 0;
}
.contact-user i {
    margin-right: 5px;
}
.contact-details-profile {
    margin-top: 25px;
}
.social-icon-section img {
    width: 35px !important;
}
.social-icon-section {
    text-align: right;
}
li.userprofile-header a {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
li.userprofile-header a img {
    width: 37px;
}
li.userprofile-header a {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
li.notification-header {
    padding-top: 5px;
    position: relative;
}
span.notification-red {
    background-color: rgb(255 0 0);
    width: 15px;
    height: 15px;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    top: 16px;
    right: -2px;
    border: 3px solid rgb(255 255 255);
}
.headerright-mobile{
    display: none;
}
.burger-menu-top img{
    width: 50px;
}
span.burger-menu-top {
    border: 2px solid #000;
    border-radius: 3px;
    padding: 3px 8px;
}
span.burger-menu-top img{
    width: 30px;
}
.header-top-right.headerright-mobile ul {
    text-align: right;
}
span#getUserEmail {
    display: inline-block;
}
.update-btn-section .btn {
    float: right;
}
span#getUserPhone {
    display: inline-block;
}
span#getUserWebsite {
    display: inline-block;
}

span#user-contact-edit-close-icon {
    margin-right: 10px;
}
span#user-education-edit-close-icon {
    margin-right: 10px;
}
li.profile-tab-li.active {
    background-color: #94c83d;
}
li.profile-tab-li.active a {
    color: #000;
}
.profile-home{
    text-align: center;
}
.profile-home h1 {
    font-size: 30px;
    font-weight: 800;
    color: #212529;
}
.profile-home p{
    color: #7c7c7c;
    font-size: 16px;
    margin-top: 0;
}
.profile-home ng-button {
    background-color: #5ab2e1;
    color: #fff;
    border: 0;
    border-radius: 4px;
    padding: 8px 25px;
    cursor: pointer;
}
.profile-home button:hover {
    background-color: #94c83d;
}
.donthave-account {
    margin-top: 20px;
    font-weight: 800;
    font-size: 18px;
    margin-bottom: 10px;
    color: #212529;
}
ion-content.md.hydrated {
    --background: rgb(255 255 255);
}
a.register-profilehome {
    font-size: 18px;
    font-weight: 700;
    color: #94c83d;
    text-decoration: none;
}
a.login-profilehome{
    font-size: 16px;
    font-weight: 700;
    color: #5ab2e1;
    margin-right: 15px;
}
.profile-home img{
    margin-bottom: 20px;
}
/* .sign-in button, .sign-in span{
    display: inline-block;
} */
.lookup-popup .select2-selection {
    background-color: #b8b9bc !important;
    color: #fff;
    font-weight: 700;
    margin-bottom: 5px;
    height: 38px !important;
	border: 0 !important;
}
.lookup-popup .select2 {
    width: 100% !important;
}
.lookup-popup span#select2-modal-state-container {
    color: #fff;
    font-weight: 600;
	line-height: 36px !important; 	
}


.registration-form .select2-selection {
    color: #fff;
    height: 38px !important;
	border: 0 !important;
}
.registration-form .select2 {
    width: 100% !important;
}
.registration-form span#select2-modal-state-container {
	line-height: 36px !important; 	
}
.select-speciality span.select2-selection {
    padding-top: 5px;
}
select#npi-results {
    color: #6b6b6b;
    font-size: 13px;
    text-transform: uppercase;
    border-bottom: 1px solid #e7e7e7;
    padding: 7px;
    cursor: pointer;
}
button.npi-results-row {
    background-color: transparent;
    font-size: 14px;
    font-weight: 400;
    color: #727272;
    border-bottom: 1px solid #999999 !important;
    padding-bottom: 5px;
    text-align: left;
    margin-bottom: 10px;
    margin-top: 0;
    width: 100%;
}
#npi-results-div {
    border: 1px solid #727272 !important;
    border-radius: 5px;
    padding: 10px 0;
    text-align: left;
    height: 130px;
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 0 15px;
}
button.npi-results-row:hover {
    background-color: transparent;
}
span.loader-npi {
    position: relative;
    left: 10px;
    top: 3px;
}
.pocn-header {
    text-align: center;
}
img.header-logo {
    width: 200px;
    margin-top: 15px;
}
.dialpad-section{
    background-image: url(assets/images-pocn/dial-bg.jpg);
}
.dialpad-outer {
    width: 100%;
    display: inline;
}
.dial-pad-section ion-col{
    display: inline-block;
}













@media (min-width: 320px) and (max-width: 480px) {
	.profile-field-section .um-customized-row{
		margin-top: 0 !important;
	}
    .registration-form{
        width: 90%;
    }
    .home-banner h2{
        margin-top: 37%;
    }
    .user-details-section {
        margin-top: 0;
        padding: 10px;
    }
    .user-avatar img {
        width: 150px;
    }

}

@media (min-width: 481px) and (max-width: 767px) {

    .home-banner h2{
        margin-top: 27%;
    }

}

@media only screen and (max-width: 767px) {

    .pocn-header-menu {
        text-align: center;
    }
    .profile-subtabs .tab-content{
        padding: 0 !important;
    }
    .user-profile-section {
        margin-top: -85px;
    }
    li.userprofile-header {
        margin-right: 15px;
    }
    .header-top-right.headerright-mobile ul{
        margin-bottom: 0;
    }
    .headerright-desktop{
        display: none;
    }
    .headerright-mobile{
        display: block;
    }
    .header-top-right.headerright-mobile li {
        list-style-type: none;
        display: inline-block;
    }
    .connection-details{
        max-width: 100%;
    }
    .engagement-score{
        font-size: 13px;
    }
    .social-icon-section {
        text-align: left;
        margin: 15px 0;
    }

    .user-profile-section{
        padding: 0;
    }
    .profile-subtabs .nav-tabs > li{
        width: 100%;
        display: block !important;
        flex-grow: unset !important;
        flex-basis: auto;
        margin-bottom: 1px;
    }
    #bookmarks .courses-top-content, #engagements .courses-top-content {
    padding: 15px 0;
    }
    #bookmarks .col-sm-6, #engagements .col-sm-6 {
    margin-bottom: 0;
}
span.bookmark-icon img {
    width: 15px;
}
span.bookmark-icon {
    position: absolute;
    right: 0px;
    top: 0px;
}
.tab-content .dept-date-section {
    padding-top: 0;
    min-height: 1px;
}
    .mobile-top-tab li span{
        display: none;
    }
    .courses-main-block {
        border-bottom: 1px solid #c2c2c2;
        border-radius: 0;
        border-right: 0;
        border-left: 0;
        border-top: 0;
    }
    .mobile-bottom-tab{
        display: block;
    }
	.home-banner h2{
		font-size: 30px;
	}
	.home-banner{
		height: 350px;
	}
    .header-menu-pocn {
        position: absolute;
        right: 0;
        top: 0;
    }
    .registration-banner{
        height: auto;
        padding-top: 140px;
        padding-bottom: 50px;
    }
    .user-avatar button{
        width: auto;
    }
    .user-avatar img {
        width: 150px;
    }
    button.login_button {
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .register-button button{
        margin-right: 10px;
        margin-top: 10px;
    }
    .footer-menu ul {
        padding-left: 0;
    }
    img.pocn-logo-header {
        width: 125px;
        margin-top: 10px;
    }
    .copyright {
        font-size: 12px;
    }
    .social-media, .copyright{
        text-align: center;
    }
    .mobile-top-tab, .mobile-bottom-tab{
        display: block;
    }
    .social-media ul{
        padding-left: 0;
    }
    

}

@media only screen and (max-width: 1024px) {

    .header-menu-pocn{
        width: 100%;
    }
    .header-menu-pocn .navbar-collapse {
        background-color: rgba(0,0,0,0.8);
        padding: 10px;
        position: relative;
        z-index: 999;
        margin-top: 5px;
    }
    .header-menu-pocn button.navbar-toggler {
    padding: 0;
}
    button.userlogout, button.user_profile {
        margin-right: 15px;
        margin-top: 10px;
    }
    .courses .col-sm-6{
        margin-bottom: 25px;
    }

}

@media (min-width: 768px) and (max-width: 1024px) {

    .contact-details-profile {
        text-align: center;
    }
    .social-icon-section img {
        max-width: 25px;
    }
    .social-icon-section {
        text-align: left;
    }
    .mobile-top-tab li img{
        display: block;
        margin: auto;
    }
    .mobile-top-tab li a{
        font-size: 12px;
    }
    .header-menu-pocn {
        position: absolute;
        right: 0;
        top: 0;
    }
    .home-banner h2{
        font-size: 40px;
        margin-top: 18%;
    }
    .home-banner{
        height: 300px;
    }
    .registration-banner{
        height: auto;
        padding-top: 140px;
        padding-bottom: 50px;
    }
    .user-avatar button{
        width: auto;
    }
    .user-avatar img {
        width: 150px;
    }
    button.login_button {
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .register-button button{
        margin-right: 15px;
    }

}



.contact-form-section ion-input, .contact-form-section ion-textarea, .contact-form-section ion-select, ion-input{
    border: 1px solid rgb(206 212 218);
    border-radius: 5px;
    color: rgb(0 0 0);
}

// @media (min-width: 320px) and (max-width: 480px) {
//     ion-row.call-start-end {
//         position: absolute;
//         left: 0;
//         right: 0;
//         bottom: 10%;
//     }
//     ion-row.calling-dialer {
//         position: absolute;
//         bottom: 35%;
//     }
//     ion-row.dial-area-section.calling-area {
//         position: absolute;
//         left: 0;
//         right: 0;
//         top: 10%;
//     }
// }

// @media (min-width: 481px) and (max-width: 767px) {
//     ion-row.call-start-end {
//         position: absolute;
//         left: 0;
//         right: 0;
//         bottom: 3%;
//     }
//     ion-row.calling-dialer {
//         position: absolute;
//         bottom: 25%;
//     }
//     ion-row.dial-area-section.calling-area {
//         position: absolute;
//         left: 0;
//         right: 0;
//         top: 2%;
//     }
// }

@media only screen and (max-width: 767px) {

    .dialpad-section{
        max-width: 100% !important;
    }
    ion-row.callerid-section{
        max-width: 100% !important;
    }
    .dialpad-section{
        height: 100%;
        position: relative;
    }
    .dial-pad-section ion-col{
        display: inline-block;
        margin: 15px!important;
        padding-top: 7px;
    }
    ion-row.dial-pad-section ion-item {
        width: 100%;
        text-align: center;
    }
    ion-row.calling-dialer{
        width: 100% !important;
    }
    ion-row.dial-pad-section ion-item .item-native{
        padding-left: 0 !important;
    }
    .dialpad-outer {
        width: 100%;
        display: inline;
    }
    


}

.alert-wrapper .alert-head {
    padding-bottom: 0;
}
.alert-wrapper button.alert-button {
    background-color: rgb(90 178 225);
    color: rgb(255 255 255);
    padding: 8px 15px;
    margin-bottom: 5px;
}
button.alert-button.alert-button-role-cancel{
    background-color: rgb(112 112 112) !important;
}

// Modal pop up width
// .alert-wrapper.ion-overlay-wrapper {
//     min-width: 35% !important;
//     max-width: 100% !important;
//   }

.alert-sub-title, h2.alert-title{
    font-size: 15px !important;
    margin-bottom: 15px !important;
    font-weight: normal !important;
}
.alert-wrapper.ion-overlay-wrapper .alert-radio-label.sc-ion-alert-md{
    font-size: 15px !important;
    padding-left: 38px !important;
}