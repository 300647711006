.ios .safe-area {
  padding-top: calc(constant(safe-area-inset-top) + 4px);
  padding-top: calc(env(safe-area-inset-top) + 4px);
}

.section {
  --padding-start: 0;
  --inner-padding-end: 0;

  .section-title {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500 !important;
    line-height: 22px;
    margin: 0;
    letter-spacing: 0.2px;
  }

  a {
    text-decoration: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--ion-color-primary);
    margin: 0;
  }
}

.item-title {
  font-weight: 500 !important;
  color: var(--ion-color-dark) !important;
}

.item-subtitle {
  color: var(--ion-color-medium) !important;
  font-weight: 300 !important;
  font-size: 13px !important;
}

.small-button {
  --border-radius: 50%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: 48px;
  height: 48px;
}

.story-button {
  --border-radius: 50%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: 52px;
  height: 52px;
  --padding-start: 0;
  --padding-end: 0;
  --box-shadow: none;
  --background: #eff3ff;

  ion-icon {
    font-size: 24px;
    color: var(--ion-color-primary);
  }
}

.swiper-pagination-bullet {
  background: #fff;
}

.onboarding .swiper-pagination-bullet {
  background: var(--ion-color-secondary) !important;
}

.swiper-slide img {
  width: max-content;
}

.gradient-button {
  --background: radial-gradient(
    circle at top left,
    var(--ion-color-secondary),
    var(--ion-color-primary)
  );
}

.loading-wrapper {
  border-radius: 10px !important;
}

.loader-text {
  margin: 14px !important;
}

.loader {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  border: 4px solid #6513e1;
  border-radius: 10px;
  left: 30%;
  animation: loader 2s infinite ease;
  margin-top: 14px;
}

.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #6513e1;
  animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

.modal-container .modal-wrapper {
  height: 65vh;
  width: 80vw;
  border-radius: 16px;
}

.buttons-modal .modal-wrapper {
  margin: 16px;
  height: 10vh;
  position: relative;
  top: 37vh;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

.avatars {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  z-index: 1;
  margin-top: 8px;

  .avatar-item {
    margin: 0;
    padding: 0;
    position: relative;
    width: 16px;
  }

  li.avatar-item:nth-child(1) {
    z-index: 1;
  }
  li.avatar-item:nth-child(2) {
    z-index: 2;
  }
  li.avatar-item:nth-child(3) {
    z-index: 3;
  }
  li.avatar-item:nth-child(4) {
    z-index: 4;
  }
  li.avatar-item:nth-child(5) {
    z-index: 5;
  }

  img.avatar-img {
    border: 2px solid rgba(255, 255, 255, 1);
    border-radius: 50%;
    display: block;
    height: 30px;
    width: 30px;
    max-width: 30px;
  }

  .avatar-numbers {
    border: 2px solid rgba(255, 255, 255, 1);
    border-radius: 50%;
    display: block;
    height: 30px;
    width: 30px;
    max-width: 30px;
    background: var(--ion-color-primary);
    text-align: center;
    line-height: 23px;

    span {
      color: #fff;
      font-size: 10px;
    }

    span::before {
      content: "+";
    }
  }
}
